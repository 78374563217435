<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="頭像" prop="avatar" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.avatar" :src="form.avatar" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>
      <a-form-model-item label="昵稱" prop="nickname" >
        <a-input v-model="form.nickname" placeholder="请输入昵稱" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex" >
        <a-select v-model="form.sex" placeholder="请选择性别">
          <a-select-option :value="1">男</a-select-option>
          <a-select-option :value="2">女</a-select-option>
          <a-select-option :value="0">保密</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="生日" prop="birthday" >
        <j-date v-model="form.birthday"></j-date>
      </a-form-model-item>
      <a-form-model-item label="用戶个性签名" prop="userSign" >
        <a-input v-model="form.userSign" placeholder="请输入用戶个性签名" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser ,addUser, updateUser } from '@/api/biz/user'
import JDate from '@/components/jeecg/JDate'
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    JDate
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        mobile: null,

        password: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        isNotice: null,

        status: 0,

        createTime: null,

        remark: null,

        email: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '用戶名/手機號，用于登录不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码，md5不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '頭像链接不能为空', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '昵稱不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        userSign: [
          { required: true, message: '用戶个性签名不能为空', trigger: 'blur' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用戶狀態，1-正常，0禁用，禁用操作请注意清除token，同时不允许用戶登录不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        password: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: 0,
        createTime: null,
        remark: null,
        email: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log("res========>"+JSON.stringify(res))
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.avatar = info.file.originFileObj.ossUrl;
      }
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
